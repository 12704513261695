var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-md-12 col-lg-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기계/장비 이력 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.saveType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.save,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.popupParam.hhmContructFacilityHistoryId
                            ? _c("c-btn", {
                                attrs: {
                                  label: "삭제",
                                  editable: _vm.editable,
                                  icon: "delete_forever",
                                },
                                on: { btnClicked: _vm.remove },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-field", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            plantCd: _vm.popupParam.plantCd,
                            vendorCd: _vm.popupParam.vendorCd,
                            data: _vm.data,
                            label: "점검자",
                            userName: "inspectionUserName",
                            name: "inspectionUserId",
                          },
                          model: {
                            value: _vm.data.inspectionUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "inspectionUserId", $$v)
                            },
                            expression: "data.inspectionUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            type: "date",
                            editable: _vm.editable,
                            label: "점검일",
                            name: "inspectionDate",
                          },
                          model: {
                            value: _vm.data.inspectionDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "inspectionDate", $$v)
                            },
                            expression: "data.inspectionDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "특이사항",
                            name: "inspectionResultDetail",
                          },
                          model: {
                            value: _vm.data.inspectionResultDetail,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "inspectionResultDetail", $$v)
                            },
                            expression: "data.inspectionResultDetail",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "col-lg-12 col-md-12 col-sm-12" },
                [
                  _c("c-table", {
                    ref: "table",
                    attrs: {
                      title: "점검항목 목록",
                      columns: _vm.grid.columns,
                      gridHeight: _vm.grid.height,
                      merge: _vm.grid.merge,
                      data: _vm.data.machineryHistoryResultModelList,
                      filtering: false,
                      isExcelDown: false,
                      columnSetting: false,
                      usePaging: false,
                      editable: _vm.editable,
                      rowKey: "hhmContructFacilityHistoryResultId",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.name === "safetyMeasure"
                              ? [
                                  props.row["checkValue"] === "N"
                                    ? _c("c-text-column", {
                                        attrs: {
                                          editable: _vm.editable,
                                          props: props,
                                          col: col,
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: props.row["safetyMeasure"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              props.row,
                                              "safetyMeasure",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "props.row['safetyMeasure']",
                                        },
                                      })
                                    : _c("span"),
                                ]
                              : _vm._e(),
                            col.name === "customCol" &&
                            props.row["sopImprovementIds"] &&
                            props.row["ibmStepNames"]
                              ? _vm._l(
                                  _vm.$comm.ibmTagItems(props.row),
                                  function (item, index) {
                                    return _c(
                                      "q-chip",
                                      {
                                        key: index,
                                        staticStyle: {
                                          "margin-bottom": "4px !important",
                                        },
                                        attrs: {
                                          color: item.color,
                                          clickable: true,
                                          "text-color": "white",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.linkClick(
                                              item,
                                              props.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(" " + _vm._s(item.title) + " "),
                                        _c("c-assess-tooltip", {
                                          attrs: {
                                            ibmTooltip: item.ibmTooltip,
                                            ibmClassCd: item.ibmClassCd,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                )
                              : _vm._e(),
                            col.name === "customCol" &&
                            _vm.popupParam.hhmContructFacilityHistoryId
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "q-btn-group",
                                      {
                                        staticClass: "ColumInnerBtnGroup",
                                        attrs: { outline: "" },
                                      },
                                      [
                                        _vm.editable && !_vm.disabled
                                          ? _c(
                                              "q-btn",
                                              {
                                                staticClass: "ColumInnerBtn",
                                                attrs: {
                                                  icon: "add",
                                                  color: "orange-6",
                                                  "text-color": "white",
                                                  align: "center",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.innerBtnClicked(
                                                      col,
                                                      props,
                                                      "1"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("q-tooltip", [
                                                  _vm._v(" 개선요청등록 "),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editable && !_vm.disabled
                                          ? _c(
                                              "q-btn",
                                              {
                                                staticClass: "ColumInnerBtn",
                                                attrs: {
                                                  icon: "add",
                                                  color: "red-6",
                                                  "text-color": "white",
                                                  align: "center",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.innerBtnClicked(
                                                      col,
                                                      props,
                                                      "2"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("q-tooltip", [
                                                  _vm._v(" 즉시조치등록 "),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }